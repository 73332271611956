import './App.css';
import Lottery from './Lottery';

function App() {
  return (
    <>
      <Lottery />
    </>
  );
}

export default App;
