import { useEffect, useState } from "react";

const iconsSet = ["🦜", "🍋", "🍎", "🍏", "🍊", "🍌", "🍉", "🍒"];
const slots = 3;

const lottery = els => {
  const total = els.length;

  return Math.floor(Math.random() * total);
}

const isWin = els => {
  if (els.length !== slots) {
    return false;
  }

  return els.every((el, _, arr) => el === arr[0])
};

const newTicket = () =>
  Array.from({ length: slots }, () => iconsSet[lottery(iconsSet)]);

export default function Lottery() {
  const [lotteryTicket, setLotteryTicket] = useState([newTicket()]);
  const [count, setCount] = useState(1);
  const [result, setResult] = useState(1);

  const updateTicket = () => {
    setLotteryTicket(newTicket());
    setCount(count + 1);
    setResult(count)
  }

  useEffect(() => {
    if (isWin(lotteryTicket)) {
      setCount(1);
    }
  }, [lotteryTicket, count]);

  return (
    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
    <div style={{display: 'grid', rowGap: '2rem'}}>
      <div>
        {lotteryTicket.map(val => val)}
      </div>
      {isWin(lotteryTicket) ? <h1 style={{color: `hsl(${result}deg 100% 50%)`}}>WYGRAŁ JEBANY ZA {result} {lotteryTicket[0]}</h1> : ''}
      <div>
        <button type="button" onClick={() => updateTicket()}>New ticket</button>
      </div>
      <div>
        c: {count}
      </div>
    </div>
    </div>
  );
}
